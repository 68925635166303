<template>
  <div class="main">
    <div class="m_top"></div>
    <div class="w1200">
      <div class="text-c m-t-39">
        <p class="font30 color333 weight">特色课程</p>
        <p class="font16 color999">Featured Programs</p>
      </div>
      <div class="m-t-28 m-b-30 m-con">
        <div class="flex-wrap">
          <div
            class="mc-li m-r-20 m-b-20"
            v-for="(item, index) in menuNav"
            :key="index"
          >
            <CourseItem
              imgw="280"
              imgh="175"
              @faBtn="faBtn(item)"
              :coverImg="item.coverImg"
              :title="item.title"
              :doctoName="item.teacher"
              :introduce="item.label"
              :price="item.salePrice"
              :original="item.markPrice"
              :playNum="item.realPlayCount"
              :free="item.free == 1 ? true : false"
            >
            </CourseItem>
          </div>
        </div>
        <div class="text-c">
          <Page
            @on-change="change"
            :total="total"
            :page-size="per_page"
            :current="current_page"
            prev-text="上一页"
            next-text="下一页"
          />
          <slot>
            <span class="ml20"
              >共有 <span class="co36">{{ total }}</span> 条数据</span
            >
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseItem from "../components/CourseItem.vue";
import { courseList } from "@/api/home";
export default {
  data() {
    return {
      total: 0, //数据总数
      order_page: 1, //页码
      current_page: 1, //当前页码
      per_page: 0, //每页显示条数
      last_page: 0, //最后一页
      menuNav: [],
    };
  },
  components: {
    CourseItem,
  },
  created() {
    this.getFeatured();
  },
  methods: {
    faBtn(item) {
      this.$router.push({
        path: "/CourseDetail",
        query: item,
      });
    },
    change(v) {
      this.order_page = v;
      this.getFeatured();
    },
    getFeatured() {
      courseList({ is: 1, handpick: 0, pageNum: this.order_page }).then(
        (res) => {
          this.menuNav = res.data.data;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.per_page = res.data.per_page;
          this.last_page = res.data.last_page;
          console.log("res", res);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.m_top {
  height: 540px;
  background: url("../assets/images/Bitmap.png") no-repeat top/ auto;
}
</style>